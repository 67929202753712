export const copyrightInfringementNotice = [
  'A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.',
  'Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.',
  'Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit Own Up to locate the material.',
  'Information reasonably sufficient to permit Own Up to contact the complaining party, such as an address, telephone number, and, if available, an email address.',
  'A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.',
  'A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.'
];

export const callConsent = [
  'By using our Service you agree to receive calls and SMS messages from us and our Providers, including telemarketing calls, auto-dialed calls and texts and pre-recorded voice messages; you have the option to opt-out (but if you opt-out you may miss out on products, services, promotions, etc.).',
  'Your mobile service provider may charge you for calls/messaging according to your plan and Own Up and/or Providers are not responsible for those charges.',
  'You agree to keep your contact information up-to-date, including your telephone number(s).',
  'The calls you make to us and the calls we make to you may be monitored and/or recorded.',
  'Your contact information will be shared with Providers (e.g., when you choose a loan product with a specific lender partner, they will need to contact you to complete the transaction you commenced).'
];

export const electronicCommunications = [
  'Agreements and Policies (e.g., the Own Up Terms & Conditions of Service and Privacy Policy), including updates to these agreements or policies;',
  'Federal and State tax statements we or our Providers are required to make available to you, if applicable; and',
  'Loan Product or other Product-related Disclosures such as Application Disclosures.'
];

export const techRequirements = [
  'a computer with an Internet connection;',
  'a current web browser that includes 128-bit encryption (e.g. Microsoft Edge 87 or newer, Safari 13.1 or newer, Firefox v. 84 or newer, Chrome V. 86 or newer) with cookies enabled;',
  'Adobe Acrobat Reader 11 or newer to open documents in .pdf format;',
  'a valid email address (your primary email address on file with Own Up); and',
  'sufficient storage space to save past Communications or an installed printer to print them.'
];

export const generalProhibitions = [
  'upload, submit, import, or transmit any User Content that: (i) infringes, misappropriates, or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates any applicable law or regulation or would give rise to civil liability; or (iii) is fraudulent, false, misleading or deceptive;',
  'use, display, mirror or frame (i) the Services or any individual element within the Services; (ii) Own Up’s name, any Own Up trademark, logo or other proprietary information; or (iii) the layout and design of any page or form contained on a page, without Own Up’s express written consent;',
  'access, tamper with, or use non-public areas of the Services, Own Up’s computer systems or the technical delivery systems of Own Up’s providers;',
  'attempt to probe, scan, or test the vulnerability of any Own Up system or network or breach any security or authentication measures;',
  'avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by Own Up or any of Own Up’s providers or any other third party (including another user) to protect the Services or Content;',
  'attempt to access or search the Services or Content or download Content from the Services through the use of any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, data mining tools, or the like) other than the software and/or search agents provided by Own Up or other generally available third-party web browsers;',
  'attempt to decipher, decompile, disassemble, or reverse engineer any of the software used to provide the Services or Content;',
  'interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;',
  'violate any applicable law or regulation; or',
  'encourage or enable any other individual to do any of the foregoing.'
];
