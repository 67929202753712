import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpHeader
} from '@rategravity/own-up-component-library';
import React from 'react';
import { Layout } from '../components/layout';
import { ContentWrapper, PageWrapper } from '../components/layout/wrappers';
import { UnderlinedLink, UnderlinedLinkInternal } from '../components/links';
import { PageTitle, PrimaryText } from '../components/typography';
import {
  callConsent,
  copyrightInfringementNotice,
  electronicCommunications,
  generalProhibitions,
  ownUpAddress,
  techRequirements
} from '../data/content/terms-conditions';

const { street, unit, city, state, zip } = ownUpAddress;

const SubheaderStyle = createOwnUpStyle({
  fontSize: 21
});

const Subheader = createOwnUpComponent(OwnUpHeader, SubheaderStyle);

// eslint-disable-next-line max-lines-per-function
export const TermsAndConditions = () => (
  <Layout>
    <PageWrapper>
      <ContentWrapper>
        <PageTitle variant="title">Terms of Use</PageTitle>
        <PrimaryText>
          <i>Last Updated: January 12, 2021</i>
          <p>
            Welcome to the RateGravity, Inc. dba Own Up (&quot;Own Up&quot;) website located at
            www.ownup.com (the &quot;Site&quot;). Please read these Terms of Use (the
            &quot;Terms&quot;) and our Privacy Policy (&quot;Privacy Policy&quot;) carefully because
            they govern your access and use of our Site and services available via the Site, which
            can be used to aggregate personal and financial data into a single experience and
            qualify for a loan. These Terms are a binding legal contract between you and Own Up,
            which sets forth your rights and obligations with respect to the Site, including
            important limitations and exclusions. To make these Terms easier to read, the Site and
            our services are collectively called the &quot;Services.&quot;
          </p>
          <p>
            The Services are for general informational purposes. They are not a substitute for
            actual legal, investment or professional advice from a licensed competent individual in
            their field of expertise. The information and services offered on this Site are provided
            with the understanding that Own Up is not engaged in rendering legal or other
            professional services or advice. Your use of the Services is subject to the additional
            disclaimers and caveats that may appear throughout the Site.
          </p>
        </PrimaryText>
        <OwnUpHeader>Agreement to Terms</OwnUpHeader>
        <PrimaryText>
          <p>
            By using the Services, you expressly agree to be bound by the terms and conditions
            herein, as of the date of your use (the “Effective Date”). Your use of the Services
            always is subject to these Terms and the Privacy Policy. If you do not agree to these
            Terms, you may not use the Services. You agree that your use of the Services always will
            be subject to the most current version of these Terms at the time of such use. The
            Services are intended for use only by users who are at least 18 years of age.
          </p>
        </PrimaryText>
        <OwnUpHeader>Changes to Terms or Services</OwnUpHeader>
        <PrimaryText>
          <p>
            We may modify the Terms at any time, at our sole discretion. If we do so, we&apos;ll let
            you know either by posting the modified Terms on the Site or through other
            communications. It&apos;s important that you review the Terms whenever we modify them
            because if you continue to use the Services after we have posted modified Terms on the
            Site, you are indicating to us that you agree to be bound by the modified Terms. If you
            don&apos;t agree to be bound by the modified Terms, then you may not use the Services
            anymore. Because our Services are evolving over time, we may change or discontinue all
            or any part of the Services, at any time and without notice, at our sole discretion. You
            can always find the current version of these Terms by checking{' '}
            <UnderlinedLinkInternal to="/terms-conditions/" style={{ overflowWrap: 'break-word' }}>
              https://www.ownup.com/terms-conditions/
            </UnderlinedLinkInternal>
            . You should do so frequently.
          </p>
        </PrimaryText>
        <OwnUpHeader>Who May Use the Services</OwnUpHeader>
        <Subheader variant="box">ELIGIBILITY</Subheader>
        <PrimaryText>
          <p>
            These Terms of Service are a legally binding contract; in order to use our Service you
            must be legally capable of entering into this contract.
          </p>
          <p>
            You may use the Service only if you are a natural person (i.e., not a business or other
            corporate entity) and can form a binding contract with Own Up. Any use or access to the
            Service by anyone under the age of majority in the State in which they reside is
            strictly prohibited and in violation of this Agreement. You must act in compliance with
            this Agreement and all applicable local, state, national, and international laws, rules
            and regulations. You are prohibited from using the Service if you have previously been
            removed from the Service by Own Up.
          </p>
        </PrimaryText>
        <Subheader variant="box">REGISTRATION AND YOUR INFORMATION</Subheader>
        <PrimaryText>
          <p>
            If you want to use certain features of the Services you&apos;ll have to create an
            account (&quot;Account&quot;). You can do this via the Site. It&apos;s important that
            you provide us with accurate, complete and up-to-date information for your Account and
            you agree to update such information, as needed, to keep it accurate, complete and
            up-to-date. If you don&apos;t, we might have to suspend or terminate your Account. You
            agree that you won&apos;t share your Account password with anyone and you&apos;ll notify
            us immediately of any unauthorized use of your Account. You&apos;re responsible for all
            activities that occur under your Account, whether or not you know about them.
          </p>
        </PrimaryText>
        <OwnUpHeader>Using the Services</OwnUpHeader>
        <PrimaryText>
          <p>
            Through the Services, Account holders may be provided the opportunity to submit personal
            or financial information (collectively, &quot;User Information&quot;) and to pre-qualify
            for a loan or receive other financial product or service recommendations. Users may
            submit their User Information by using an online form or by uploading documentation.
            Account holders may also import User Information maintained by third parties and
            third-party services with whom they have an account or contractual relationship
            (&quot;Third-Party Information&quot;).
          </p>
          <p>
            When submitting User Information to pre-qualify for a loan or access other services on
            the Site, you agree to provide current, complete, and accurate information about
            yourself. Own Up is not responsible for the accuracy or legality of the User Information
            you submit, including any Third-Party Information you import. If any User Information
            you submit is untrue, inaccurate, not current or incomplete, Own Up has the right to
            terminate your Account or any loan qualification. Except as prohibited by law, Own Up
            reserves the right to decline your loan application for any reason. We may verify your
            identity. You authorize us to make any inquiries we consider necessary to validate your
            identity. These inquiries may include asking you for further information, requiring you
            to provide your full address, your social security number and/or requiring you to take
            steps to confirm ownership of your email address or financial instruments, ordering a
            credit report, or verifying information you provide against third party databases or
            through other sources. If you do not provide this information or Own Up cannot verify
            your identity, we can refuse to allow you to use the Services.
          </p>
        </PrimaryText>
        <OwnUpHeader>Detailed Services</OwnUpHeader>
        <PrimaryText>
          <p>
            The Own Up service allows you to complete a residential mortgage qualification. To
            participate in this service, you agree to provide requested information to Own Up after
            agreeing to the full Electronic Consent, these Terms of Service and our Privacy Policy.
            Upon completion of your qualification, you may elect to receive offers from Lenders.
          </p>
        </PrimaryText>
        <Subheader variant="box">TERMS APPLICABLE TO ALL MORTGAGE LOAN INQUIRY SERVICES</Subheader>
        <PrimaryText>
          <p>
            Own Up is a loan qualification and online marketplace that enables consumers to receive
            offers for mortgage loan products, and is required to be licensed as a Mortgage Broker.
            Own Up DOES NOT take formal mortgage applications (commonly referred to as Form 1003).
            Own Up DOES NOT make loans or credit decisions in connection with loans, nor does Own Up
            issue commitments or lock-in agreements. Any loan inquiry you submit is NOT an
            application for credit. Rather, it is an inquiry to be matched with Lenders to receive
            loan offers from Lenders. You will have to complete a formal application with the Lender
            you choose before they will extend an unconditional offer. A Lender you select may
            require you to pay an application or other fee to cover the costs of an appraisal,
            credit report or other items. The Lender, not Own Up, will determine the amount of any
            such fee and should provide information to you regarding the refundability of any such
            fee. Own Up does not endorse or recommend the products of any particular Lender. Except
            as otherwise provided for your state, Own Up is not acting as your agent or as the agent
            of any Lender. You should rely on your own judgment in deciding which available loan
            product, terms and Lender best suits your needs and financial situation. The Lender is
            solely responsible for its services to you, and you agree that Own Up shall not be
            liable for any damages or costs arising out of or in any way connected with your use of
            its Services. You understand that Lenders may keep your loan request information and any
            other information provided by Own Up or received by them in the processing of your loan
            request, whether or not you are qualified for a loan with them or if you make a loan
            with them. You agree to notify any particular Lender directly if you no longer want to
            receive communications from them. In addition, the information you provide Own Up is
            required to be housed and securely maintained for State Licensing Record Retention
            requirements and cannot be removed, purged, or destroyed until the expiration of the
            individual state prescribed period. Lenders may also from time-to-time be required to
            share your completed Loan information with Own Up for record retention requirements,
            customer service, internal marketing and analytics purposes. You are providing express
            written consent for the retention of the same upon completion of an inquiry form for Own
            Up and the selected Lender to share this information for this purpose.
          </p>
          <p>
            The Websites and the Services provided by Own Up are available in connection with
            mortgage loans made on real property located in states in which we are licensed. Loans
            may only be made to residents of, or secured by real property located in, states where
            Lenders are licensed or authorized to make such loans. Lenders are not attempting to
            make loans outside of their authorized states or country by participating in and
            offering their products on the Websites. Own Up Lenders expressly reserve the right to
            discontinue, suspend or terminate the offering of any loan product in any specific state
            through the Websites at any time, without prior notice.
          </p>
          <p>
            The data and other information you may provide Own Up is not, and is not treated as, an
            application for a loan. Own Up does not guarantee acceptance into any particular loan
            program or specific loan terms or conditions with any Lender; loan approval standards
            are established and maintained solely by individual Lenders. Likewise, Own Up does not
            guarantee that the loan terms or rates offered and made available by Lenders are the
            best terms or lowest rates available in the market. Own Up&apos;s Network of Lenders
            does not represent all potential Lenders in your area. You are free to select any
            Lenders to speak with to shop and compare your mortgage product, but you may also choose
            none and compare additional local Lenders who may in fact have better and/or lower
            products, rates, and terms. A Lender&apos;s offer may be subject to market conditions,
            approval and qualification. The rates and fees actually provided by Lenders may be
            higher or lower depending on your complete credit profile, collateral/property
            considerations including but not limited to location, equity and value and income/asset
            consideration including, but not limited to loan to value and debt to income ratios.
            Unless expressly stated in writing, nothing contained herein shall constitute an offer
            or promise for a loan commitment or interest rate lock-in agreement. Lenders may not
            offer all products as well as not offer products in all states.
          </p>
          <p>
            Own Up will perform a soft pull of credit regardless if you provide your social security
            number or not. In order to help the government fight identity theft, the funding of
            terrorism and money laundering activities, and to help attempt to verify your identity,
            Own Up and its Lenders may obtain, verify and record information that identifies each
            person who opens an account with us and them. Own Up and its Lenders may ask for your
            name, Social Security Number, address, telephone number, date of birth and other
            important information including, but not limited to information received from your
            credit file, that will allow us and them to properly identify you. It is possible that a
            Lender may perform a soft inquiry on your credit at the same time for the same or
            similar purposes, but the soft inquiry into your credit does not impact your credit
            score. Not all lenders will receive your Social Security Number and, for those that do,
            not all will perform the soft inquiry of credit. During the Lender application process,
            they may also ask to see your driver&apos;s license or other identifying documentation
            to further properly identify you; in addition the Lender will be required to do a hard
            inquiry of your credit that will impact your credit score.
          </p>
          <p>
            Own Up is paid a fee by Lenders for the goods, facilities and services provided. Lenders
            are not to directly charge you for any such fee. If you do in fact close a loan with an
            Own Up marketplace Lender, or any other Lender you choose, at closing you will be
            responsible for paying for any settlement or closing costs associated with your loan
            (such as loan processing, underwriting, or funding fees, title insurance premiums,
            notary fees, etc.).
          </p>
          <p>Own Up does not charge you a fee for its services.</p>
          <p>
            When you complete an inquiry online, by clicking on any button indicating an acceptance,
            acknowledgement or agreement to terms, a continuance of processing or submission
            (&quot;submission&quot;) you understand that you are consenting, acknowledging and
            agreeing to the stated terms and conditions of that submission and that you are
            submitting an inquiry as to a lending product through the Own Up Marketplace which will
            match you with participating lenders. If you do not receive any offers, Own Up will
            continue to try to find you matches for similar mortgage products for which you sought
            as well as alternative products.
          </p>
          <p>
            You are also indicating that you are consenting, acknowledging, and agreeing to receive
            important notices, disclosures, and other communications (&quot;Disclosures and
            Communications&quot;) in electronic form (either by email or via the Internet). In
            addition, you acknowledge that Own Up, and one or more of its Lenders with whom you are
            matched, may access your credit file even if your Social Security Number is not provided
            (via a soft inquiry of credit) to perform its anti-money laundering due diligence and/or
            for other reasons in compliance with other federal regulations. In addition, upon
            clicking any button indicating acceptance, acknowledgment, or agreement to these and
            other terms, you have received and reviewed and, where applicable, signed the necessary
            required disclosures and/or agreements. By submitting the loan inquiry online you are in
            effect providing your electronic signature, you are extending an express invitation to
            receive offers from participating lenders and you may be contacted by telephone or email
            at the numbers and email address you have provided so they may assist you with your
            transaction, and you hereby consent to any such calls even if your phone number is on
            any Do Not Call list, or by email at the email address you provided or at another
            address that may be associated with you that we receive from Lenders or other parties
            and you hereby consent to any such email so it will not be considered spam or
            unauthorized by any local, state or federal law or regulation. In addition, you are
            providing express written consent that Own Up, a Lender that has made you an offer or an
            associated third party (Partner) may use an automatic dialing system or by pre-recorded
            message in connection with calls made to any telephone number you entered, even if it is
            a cellular phone number or other service for which the called person(s) could be charged
            for such call. By submitting your inquiry for a loan product, you are consenting to be
            contacted by one or more Lenders which may include any one of our Own Up Marketplace
            Lenders, who may either by telephone (on a recorded line), text message, email or mail
            based on the information you have provided to us, even if your telephone number or
            e-mail is on a corporate, state, or the National Do Not Call Registry. In addition, you
            are consenting, acknowledging and agreeing to Own Up and the applicable Lenders Terms of
            Use and Privacy Policy.
          </p>
          <p>
            By saving your information with Own Up or by completing a loan inquiry you give Own Up
            permission to retain all information you submitted for the prescribed State Specific
            Record Retention period and to make live, manually dialed, automatic dialed or
            pre-recorded calls, emails, direct mailing or other communication to discuss, provide or
            remind you of any information in relationship to your submission, including incomplete
            loan inquiry, the delivery of loan request matches, deadlines, quality of services or
            other matters in connection with your loan inquiry. For any service, you represent that
            all of the information you have provided in your submission and loan inquiry is true,
            accurate and complete to the best of your knowledge.
          </p>
        </PrimaryText>

        <Subheader variant="box">TERMS APPLICABLE TO ALL INSURANCE SERVICES</Subheader>
        <PrimaryText>
          <p>
            Own Up Settlement Services LLC and Own Up Insurance Services LLC allows you to obtain
            title and property and casualty insurance quotes and obtain insurance policies in
            conjunction with your home financing transaction. You agree that by providing
            information to Own Up and agreeing to these Terms of Use and our Privacy Policy, you
            give your express informed consent to Own Up Settlement Services LLC and Own Up
            Insurance Services LLC to utilize your information to provide you accurate insurance
            quotes and may send your information to vendors for the purpose of binding insurance
            policies.
          </p>
        </PrimaryText>
        <Subheader variant="box">TERMS APPLICABLE TO REAL ESTATE BROKERAGE TRANSACTIONS</Subheader>
        <PrimaryText>
          <p>
            Own Up Real Estate Services LLC allows you to be connected with a licensed real estate
            agent in your area. You agree that we may provide your information to a real estate
            brokerage so they may connect you with a licensed real estate agent.
          </p>
          <p>
            You have the right to receive any document in non-electronic form and to withdraw your
            consent to electronic delivery at any time by contacting Own Up Customer Service at{' '}
            <UnderlinedLink href="tel:844-947-2848">844-947-2848</UnderlinedLink> or by emailing{' '}
            <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink>.
          </p>
          <p>
            When you &quot;submit&quot; information, create an account or otherwise register for
            services through Own Up and its Companies&apos; websites or otherwise, you hereby
            understand and agree that you have established a business relationship between you and
            Own Up, which is the owner of this Site. Accordingly, Own Up and/or its Affiliates may
            send your information to its affiliates, Lenders, Partners, Providers as provided in the
            Privacy Policy. In addition, you agree that Own Up may contact you using information you
            provided with information and offers of services available through Own Up and the
            Websites. You hereby provide express written consent to any such communication or phone
            calls even if your phone number is on any Do Not Call list, federal, state, or
            corporate. You also agree that calls may be placed live, by automatic dialing, and/or by
            pre-recorded message. Charges may apply to a mobile phone if that is the number you
            provided. Communications may also be by email, direct mail, and/or text message. If at
            any time you do not wish to continue to receive communications from Own Up or its
            Companies&apos; or its affiliates&apos; communications, you may modify your preferences
            by calling <UnderlinedLink href="tel:844-947-2848">844-947-2848</UnderlinedLink> or by
            emailing{' '}
            <UnderlinedLink href="mailto:privacy@ownup.com">privacy@ownup.com</UnderlinedLink>{' '}
            directly if you no longer want to receive other forms of communications from us.
          </p>
        </PrimaryText>
        <OwnUpHeader>Credit Report Terms of Service</OwnUpHeader>
        <PrimaryText>
          <p>
            Please carefully review the following rules that govern our Credit Reporting Policy.
            This policy applies to the World Wide Web site owned, operated, licensed, and controlled
            by Own Up, located at <UnderlinedLinkInternal to="/">ownup.com</UnderlinedLinkInternal>,
            and all associated Own Up sites linked to this Web site (collectively, the
            &quot;Site&quot;). This Site is the property of Own Up. By using this Site and
            requesting a credit inquiry, you agree to our Credit Reporting Policy, Terms and
            Conditions of Use, and Privacy Policy. If you do not agree, do not use this Site.
          </p>
          <p>
            Own Up reserves the right at its sole discretion, to change, modify, add or remove
            portions of this Credit Reporting Policy, Terms of Use, and our Privacy Policy, at any
            time. It is your responsibility to check these policies and terms periodically for
            changes. Your continued use of the Site following the posting of changes will mean that
            you accept and agree to the changes. As long as you comply with these terms and
            policies, Own Up grants you a personal, non-exclusive, non-transferable, limited
            privilege to enter and use the Site.
          </p>
        </PrimaryText>
        <Subheader variant="box">
          USE OF CREDIT PROFILE FOR PRE-QUALIFICATION AND IDENTITY VERIFICATION
        </Subheader>
        <PrimaryText>
          <p>
            By requesting to receive loan offers from our lender partners, you are certifying that
            you understand the services being requested are regulated by the Fair Credit Reporting
            Act and that permissible purpose is required. Any special procedures established by Own
            Up for obtaining your authorization to receive information from your personal credit
            profile from Experian and TransUnion have been met. Furthermore, you certify that you
            have initiated a transaction with Own Up, and that the services being requested will be
            used solely to pre-qualify you and to confirm your identity to avoid fraudulent
            transactions in your name.
          </p>
        </PrimaryText>
        <Subheader variant="box">CONSUMER CREDIT INQUIRIES</Subheader>
        <PrimaryText>
          <p>
            We may request consumer reports on you in connection with your pre-qualification for
            credit and subsequently by our lender partners in connection with any extension of
            credit, update, renewal, review or collection of your account or any other lawful
            purpose. You are allowed to receive one free credit report under the Fair Credit
            Reporting Act from each of the three U.S. national credit reporting agencies (Experian,
            Equifax, and TransUnion) during any twelve-month period. You may also be able to receive
            free credit reports as permitted by state law (you may contact your state or local
            consumer protection agency or state attorney general to learn more about your rights
            under state law). For information on obtaining a free credit report from Experian,
            Equifax or TransUnion you may contact them directly at{' '}
            <UnderlinedLink href="https://www.experian.com" rel="noreferrer" target="_blank">
              www.experian.com
            </UnderlinedLink>
            ,{' '}
            <UnderlinedLink href="https://www.equifax.com" rel="noreferrer" target="_blank">
              www.equifax.com
            </UnderlinedLink>
            , and{' '}
            <UnderlinedLink href="https://www.transunion.com" rel="noreferrer" target="_blank">
              www.transunion.com
            </UnderlinedLink>
            .
          </p>
        </PrimaryText>
        <OwnUpHeader>Third-Party Information</OwnUpHeader>
        <PrimaryText>
          <p>
            If you choose to import Third-Party Information, you will be directly connected to the
            third-party website that maintains such Third-Party Information (&quot;Third-Party
            Website&quot;). Own Up will submit information, including usernames and passwords, that
            you provide to log into the Third-Party Website (such information comprising a
            &quot;Third-Party Login&quot;). You hereby authorize and permit Own Up to use and store
            Third-Party Logins submitted by you to accomplish the foregoing. For purposes of these
            Terms and solely to import Third-Party Information from the Third-Party Website pursuant
            to your request, you grant Own Up a limited power of attorney, and appoint Own Up as
            your attorney-in-fact and agent, to access the Third-Party Website, retrieve the
            Third-Party Information and use the Third-Party Information with the full power and
            authority to do and perform each thing necessary in connection with such activities, as
            you would do yourself. YOU ACKNOWLEDGE AND AGREE THAT WHEN OWN UP IS ACCESSING AND
            RETRIEVING THIRD-PARTY INFORMATION FROM ANY THIRD-PARTY WEBSITE, OWN UP IS ACTING AS
            YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY THAT MAINTAINS THE
            WEBSITE. Your access to the Third-Party Information is governed solely by the agreement
            between you and the operator of the Third-Party Website. If you choose to import
            Third-Party Information, you are responsible for ensuring that such action complies with
            the applicable Terms and Conditions between you and the Third-Party Website. Own Up
            cannot always foresee or anticipate technical or other difficulties which may result in
            failure to obtain data or loss of data, personalization settings or other service
            interruptions. Own Up cannot assume responsibility for the timeliness, accuracy,
            deletion, non-delivery, or failure to store any user data, communications or
            personalization settings.
          </p>
        </PrimaryText>
        <OwnUpHeader>Privacy Policy</OwnUpHeader>
        <PrimaryText>
          <p>
            Own Up recognizes the importance of protecting the privacy of Site users and wishes to
            provide efficient and relevant information to such users. In order to serve its
            customers, Own Up collects and analyzes information provided by you when using the Site.
            Our Privacy Policy describes Own Up&apos;s information practices and procedures for
            personal information we collect at this Site. We strongly urge you to read our{' '}
            <UnderlinedLinkInternal to="/privacy/" aria-label="Own Up Privacy Policy">
              Privacy Policy
            </UnderlinedLinkInternal>
            .
          </p>
        </PrimaryText>
        <OwnUpHeader>Content and Content Rights</OwnUpHeader>
        <PrimaryText>
          <p>
            For purposes of these Terms: (i) &quot;Content&quot; means text, graphics, images,
            music, software, audio, video, works of authorship of any kind, and information or other
            materials that are posted, generated, provided or otherwise made available through the
            Services; and (ii) &quot;User Content&quot; means any Content that you provide to be
            made available through the Services, including User Information and any Third-Party
            Logins that you provide. Content includes without limitation User Content.
          </p>
        </PrimaryText>
        <OwnUpHeader>Content Ownership and Responsibility</OwnUpHeader>
        <PrimaryText>
          <p>
            Own Up does not claim any ownership rights in any User Content and nothing in these
            Terms will be deemed to restrict any rights that you may have to use and exploit your
            User Content. Subject to the foregoing, Own Up and its licensors exclusively own all
            rights, title and interest in and to the Services and Content, including all associated
            intellectual property rights. You acknowledge that the Services and Content are
            protected by copyright, trademark and other laws of the United States and foreign
            countries. You agree not to remove, alter or obscure any copyright, trademark, service
            mark or other proprietary rights notices incorporated in or accompanying the Services or
            Content.
          </p>
        </PrimaryText>
        <OwnUpHeader>Rights in User Content Granted by You</OwnUpHeader>
        <PrimaryText>
          <p>
            By making any User Content available through Services you hereby grant to Own Up a
            non-exclusive, transferable, sublicensable, worldwide, royalty-free license to use,
            copy, modify, create derivative works based upon and distribute your User Content in
            connection with operating and providing the Services and Content to you, including,
            without limitation, for developing, manufacturing and marketing products. Terminating
            your account on the Site will not impact any of the rights of Own Up to any of the User
            Content. Own Up may refuse or remove User Content without notice to you. However, Own Up
            has no obligation to monitor User Content, and you agree that neither Own Up nor its
            affiliates, employees, or agents will be liable for User Content or any loss or damage
            resulting from User Content. Except as provided in the Privacy Policy, Own Up does not
            guarantee that User Content will be private, even if the User Content is in a
            password-protected area. Accordingly, you should not provide User Content that you want
            protected from disclosure to others.
          </p>
          <p>
            You are solely responsible for all your User Content. You represent and warrant that you
            own all your User Content or you have all rights that are necessary to grant us the
            license rights in your User Content under these Terms. You also represent and warrant
            that your User Content is accurate to the best of your knowledge. You represent and
            warrant that neither your User Content, nor your use and provision of your User Content
            to be made available through the Services, nor any use of your User Content by Own Up on
            or through the Services will infringe, misappropriate or violate a third party&apos;s
            intellectual property rights, or rights of publicity or privacy, or result in the
            violation of any applicable law or regulation.
          </p>
        </PrimaryText>
        <OwnUpHeader>Rights in Content Granted by Own Up</OwnUpHeader>
        <PrimaryText>
          <p>
            Subject to your compliance with these Terms, Own Up grants you a limited, non-exclusive,
            non-transferable, non-sublicensable license to download, view, copy, display and print
            the Content solely in connection with your permitted use of the Services and solely for
            your personal and non-commercial purposes.
          </p>
          <p>
            If you believe that your work has been copied in a way that constitutes copyright
            infringement, you should send written notification thereof, in accordance with the
            provisions of the Digital Millennium Copyright Act (the &quot;Notification&quot;), to:
          </p>
          <p>
            Own Up <br />
            {street}, {unit} <br />
            {`${city}, ${state} ${zip}`} <br />
          </p>
          <p>
            All of the content included in this Site is subject to the copyright laws of the United
            States and other applicable jurisdictions and Own Up or its suppliers owns all the
            copyright rights associated with this content. All rights reserved. Pursuant to Title
            17, United States Code, Section 512(c)(2), notification of claimed copyright
            infringement should be sent to the Designated Agent listed above. ALL INQUIRIES NOT
            RELEVANT TO THE FOLLOWING PROCEDURE WILL NOT RECEIVE A RESPONSE. ALL OTHER INQUIRIES NOT
            COPYRIGHT RELATED, SUCH AS REQUESTS FOR PRODUCT INFORMATION, EMPLOYMENT, ETC. MUST BE
            SENT TO THE FOLLOWING CONTACT{' '}
            <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink>.
          </p>
          <p>
            See Notice for Claims of Copyright Infringement Pursuant to 17 U.S.C. § 512(c), to be
            effective, the Notification must include the following:
          </p>
          <ol>
            {copyrightInfringementNotice.map((n, i) => (
              <li key={i}>{n}</li>
            ))}
          </ol>
          <p>
            This process only relates to reporting a claim of copyright infringement. Messages
            related to other matters will not receive a response through this process.
          </p>
        </PrimaryText>
        <OwnUpHeader>
          Your Consent to Telephone Calls, Recording of Telephone Calls and SMS Messages
        </OwnUpHeader>
        <PrimaryText>
          <ul>
            {callConsent.map((p, i) => (
              <li key={i} style={{ marginBottom: '15px' }}>
                {p}
              </li>
            ))}
          </ul>
          <p>
            <b>Consent to receive telephone calls, SMS messages, and other messages.</b> You
            acknowledge that by voluntarily providing your telephone number(s) to Own Up, you
            authorize us and our Providers (which includes our and our Provider&apos;s agents and
            representatives) to contact you using automatic telephone dialing systems, artificial or
            pre-recorded voice message systems, and automated text messaging systems in order to
            provide you with information regarding your Account, promotional offers, any transaction
            with us and with our Providers, and/or your relationship with Own Up. You agree to
            receive such calls and text messages even if you cancel your account or terminate your
            relationship with us or our Providers, except if you opt out, as provided below. You
            expressly authorize us and our Providers to make such contacts using any telephone
            numbers (including wireless, landline, and VOIP numbers) you have supplied or will
            supply to us or our Providers in connection with your Account or any other phone numbers
            we can reasonably associate with your Account through lawful means, such as skip
            tracing, caller ID capture, or other means. You understand that anyone with access to
            your telephone may listen to or read the messages we leave or send you. You agree that
            we will have no liability in connection with third parties accessing your telephone.
            Consent to receive promotional automated marketing calls/texts is not a condition of
            purchasing any goods or services or obtaining a loan. Calls or text messages to you may
            be made by or on behalf of Own Up or our Providers even if your telephone number is
            registered on any state or federal Do Not Call list.
          </p>

          <p>
            <b>Sharing your phone number with Providers.</b> You understand and agree that we may
            share your telephone number with Providers and/or their respective agents who may
            require further information prior to providing a response to your submission of
            information through the Service or to contact you regarding a product or service. You
            hereby authorize Providers to contact you directly and to contact Own Up.
          </p>
          <p>
            <b>The phone numbers you provide.</b> You represent and warrant that you are the owner
            and/or primary user of any phone number you provide to us. Should any of your telephone
            numbers change, you agree to notify us before the change goes into effect by email
            and/or updating your account profile. You agree to indemnify, defend, and hold us and
            our Providers harmless from and against any and all claims, losses, liability, costs,
            and expenses (including reasonable attorneys&apos; fees) arising from failure to update
            your contact information (including your telephone number), your voluntary provision of
            a telephone number that is not your own, and/or your violation of any federal, state, or
            local law, regulation, or ordinance.
          </p>
          <p>
            <b>Charges from your telephone provider.</b> You understand that your cellular or mobile
            telephone provider may charge you fees for calls that we or our Providers make or texts
            that we or our Providers send to you according to the type of plan you carry, and you
            agree that we will have no responsibility or liability whatsoever for such fees.
          </p>
          <p>
            <b>Opt-Out.</b> At any time, you may withdraw your consent to receive (1) automated
            telephone calls; (2) automated SMS messages, and/or (3) telemarketing calls. You must
            (i) provide us with written notice revoking your consent; (ii) in that written notice,
            you must include your full name, mailing address, email address used to contact you, and
            the specific phone number(s) for which you are revoking consent; and (iii) send this
            written notice to Own Up 101 Arch St., Ste 410, Boston, MA 02110 (Attn: Opt-Out).
            Alternatively, to stop marketing text messages, simply reply &quot;STOP&quot; to any
            marketing text message that we send you. You acknowledge and agree to accept a final
            text message confirming your opt-out. Opting out may prevent you from receiving messages
            regarding products, services, updates, improvements, or special promotions. Please allow
            up to thirty (30) days to process any opt-out request. To stop receiving automated
            telephone calls, automated SMS messages and/or telemarketing calls from our Providers,
            you must follow their procedures on their respective websites.
          </p>
          <p>
            Please note that if you opt out of automated calls, we and our Providers reserve the
            right to make non-automated calls to you relating to your account, any transaction, or
            your relationship with us. Your obligations under this Section shall survive termination
            of these Terms of Service. If you have any questions about opting out, please contact us
            via email at{' '}
            <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink> or by
            calling Customer Success at{' '}
            <UnderlinedLink href="tel:844-947-2848">(844) 947-2848</UnderlinedLink>.
          </p>
          <p>
            <b>Call Recording and Monitoring.</b> Calls to and from Own Up and its Providers (or
            third parties acting on behalf of Own Up or its Providers) may be recorded or monitored
            for quality assurance, customer service, training and/or risk management purposes. You
            agree to such monitoring and recording unless you expressly inform the agent at the
            outset of the conversation that you do not want the call to be monitored or recorded.
          </p>
        </PrimaryText>
        <OwnUpHeader>Your Consent to Electronic Communications</OwnUpHeader>
        <PrimaryText>
          <p>
            <b>Electronic Communications Delivery Policy (E-SIGN Policy).</b> Since we are an
            Internet-based company, in order to use our Service you must agree to receive all
            important information from us and our Providers electronically (by us posting it on our
            Site or us or our Providers sending you an e-mail), instead of receiving paper copies in
            the mail.
          </p>
          <p>
            The information in this section lets you know what technology you need to be able to
            view, save and/or print the Communications we send you, and that by using the Service
            you are agreeing that you have these technological capabilities. This section also lets
            you know that you must keep your contact information up-to-date.
          </p>
          <p>
            You also have the right to opt-out of receiving Communications from us and our Providers
            electronically, but if you do you may not be able to use our Service.
          </p>
          <p>
            <b>Electronic Delivery of Communications.</b> You agree and consent to receive
            electronically all non-telephonic communications, agreements, documents, notices, and
            disclosures (collectively, &quot;Communications&quot;) that we or our Providers provide
            in connection with your use of the Service. Communications include, but are not limited
            to:
          </p>
          <ul>
            {electronicCommunications.map((p, i) => (
              <li key={i} style={{ marginBottom: '15px' }}>
                {p}
              </li>
            ))}
          </ul>

          <p>
            We will provide these Communications to you by posting them on the www.ownup.com website
            and/or by emailing them to you at the email address listed in your Own Up profile.
          </p>

          <p>
            <b>Hardware and Software Requirements.</b> In order to access and retain electronic
            Communications, you will need the following computer hardware and software:
          </p>
          <ul>
            {techRequirements.map((p, i) => (
              <li key={i} style={{ marginBottom: '15px' }}>
                {p}
              </li>
            ))}
          </ul>
          <p>
            By using the Service you are giving your consent to, and you are confirming that you
            have access to the necessary equipment and are able to receive, open, and print or
            download a copy of any Communications for your records. You may print or save a copy of
            these Communications for your records as they may not be accessible online at a later
            date.
          </p>
          <p>
            <b>Additional Mobile Technology Requirements.</b> If you are accessing the Service and
            the Communications electronically via a mobile device (such as a smartphone, tablet, and
            the like), in addition to the above requirements you must make sure that you have
            software on your mobile device that allows you to print and save the Communications
            presented to you. These applications can be found for most mobile devices in the
            device&apos;s respective &quot;app store&quot;. If you do not have these capabilities on
            your mobile device, please access the Service through a device that provides these
            capabilities.
          </p>
          <p>
            <b>How to Withdraw Your Consent.</b> You may withdraw your consent to receive
            Communications electronically by writing to us at Own Up 101 Arch St., Ste 410, Boston,
            MA 02110 (Attn: Electronic Communications Delivery Policy), or by contacting us via the
            &quot;Contact Us&quot; link at the bottom of the www.ownup.com website. If you fail to
            provide your consent or if you withdraw your consent to receive Communications
            electronically, Own Up and its Providers reserve the right, within our or their
            discretion and at our or their election, to either deny your use of the Service or
            restrict, close or deactivate your Account, or charge you additional fees for paper
            copies.
          </p>
          <p>
            If, after you consent to receive Communications electronically, you would like a paper
            copy of a Communication we previously sent you, you may request a copy within 180 days
            of the date we provided the Communication to you by contacting us as described above. We
            will send your paper copy to you by U.S. mail. In order for us to send you paper copies,
            you must have a current street address on file as your physical address in your Own Up
            profile. If you request paper copies, you understand and agree that Own Up may charge
            you a Records Request Fee of up to $5 for each Communication.
          </p>
          <p>
            <b>Updating your contact information.</b> It is your responsibility to keep your email
            address up to date so that Own Up can communicate with you electronically. You
            understand and agree that if Own Up sends you an electronic Communication but you do not
            receive it because your email address on file is incorrect, out of date, blocked by your
            service provider, or you are otherwise unable to receive electronic Communications, Own
            Up will be deemed to have provided the Communication to you.
          </p>
          <p>
            Please note that if you use a spam filter that blocks or re-routes emails from senders
            not listed in your email address book, you must add Own Up to your email address book so
            that you will be able to receive the Communications we send to you.
          </p>
          <p>
            You can update your email address or street address at any time by calling us. If your
            email address becomes invalid such that electronic Communications sent to you by Own Up
            are returned, Own Up may deem your Account to be inactive, and you will not be able to
            transact any activity using your Account until we receive a valid, working email address
            from you.
          </p>
        </PrimaryText>
        <PrimaryText>
          <Subheader variant="box">
            GENERAL PROHIBITIONS AND OWN UP&apos;S ENFORCEMENT RIGHTS
          </Subheader>
          <p>You agree not to do any of the following:</p>
          <ul>
            {generalProhibitions.map((p, i) => (
              <li key={i} style={{ marginBottom: '15px' }}>
                {p}
              </li>
            ))}
          </ul>
          <p>
            Although we&apos;re not obligated to monitor access to or use of the Services or Content
            or to review or edit any Content, we have the right to do so for the purpose of
            operating the Services, to ensure compliance with these Terms, and to comply with
            applicable law or other legal requirements. We reserve the right, but are not obligated,
            to remove or disable access to any Content, including User Content, and the Services, at
            any time and without notice, including, but not limited to, if we, at our sole
            discretion, consider any of your actions or any User Content to be in violation of these
            Terms. We have the right to investigate violations of these Terms or conduct that
            affects the Services. We may also consult and cooperate with law enforcement authorities
            to prosecute users who violate the law.
          </p>
        </PrimaryText>
        <OwnUpHeader>Links to Third-Party Websites or Resources</OwnUpHeader>
        <PrimaryText>
          <p>
            The Services may contain links to third-party websites or resources (“Affiliate Sites”).
            We provide these links only as a convenience and are not responsible for the content,
            products or services on or available from Affiliate Sites. These Terms apply only to
            your use of the Services. These Affiliate Sites may have their own terms of use and
            privacy policies, which Own Up does not control and are not addressed by these Terms.
            Own Up does not monitor, endorse or guarantee, and assumes no responsibility for,
            Affiliate Sites or any transactions or information you may undertake or receive from
            Affiliate Sites. OWN UP HAS NO CONTROL OVER AFFILIATE SITES OR THE CONTENT,
            TRANSACTIONS, OR SERVICES WITHIN THEM. OWN UP CANNOT AND DOES NOT GUARANTEE, REPRESENT,
            OR WARRANT THAT THE CONTENT CONTAINED IN THE AFFILIATE SITES, INCLUDING OTHER LINKS, IS
            ACCURATE, LEGAL, OR INOFFENSIVE. OWN UP HAS NO LIABILITY FOR AFFILIATE SITES&apos;
            FAILURE TO PERFORM OR ANY ERRORS IN OR OMISSIONS REGARDING ANY INFORMATION WITHIN
            AFFILIATE SITES. OWN UP DOES NOT REPRESENT OR WARRANT THAT AN AFFILIATE SITE WILL NOT
            CONTAIN COMPUTER VIRUSES OR OTHER HARMFUL CODE THAT COULD IMPACT YOUR COMPUTER OR OTHER
            WEB ACCESS DEVICE. OWN UP DISCLAIMS ANY AND ALL LIABILITY FOR, OR IN CONNECTION WITH,
            ANY TRANSACTION OR ACTIVITY RELATED TO AFFILIATE SITES. If you have a specific dispute
            or concern related to the content, product or services that needs to be addressed by an
            Affiliate Site, you should contact the Affiliate Site and not Own Up. You acknowledge
            sole responsibility for and assume all risk arising from, your use of any third-party
            websites or resources.
          </p>
        </PrimaryText>
        <OwnUpHeader>Termination</OwnUpHeader>
        <PrimaryText>
          <p>
            We may terminate your access to and use of the Services, at our sole discretion, at any
            time and without notice to you. You may cancel your Account at any time by sending an
            email to us at{' '}
            <UnderlinedLink href="mailto:contact@ownup.com">contact@ownup.com</UnderlinedLink>. Upon
            any termination, discontinuation or cancellation of Services or your Account, the
            following provisions will survive: “Content and Content Rights,” “Content Ownership and
            Responsibility,” “Termination,” “Warranty Disclaimers,” “Indemnity,” “Limitation of
            Liability,” “Dispute Resolution”, and “General Terms.”
          </p>
        </PrimaryText>
        <OwnUpHeader>No Recommendations or Professional Advice</OwnUpHeader>
        <PrimaryText>
          <p>
            We are not providing you any advice or recommendations about any specific product or
            service.
          </p>
          <p>
            Instead, what we do is try to provide you as much information about the products or
            services based on the information you provide us or authorize us to go out and get
            (e.g., your answers to questions, a soft credit pull, preferences you have identified,
            etc.) so that you can compare your options side-by-side to make the right choices for
            yourself.
          </p>
          <p>
            We encourage you to seek advice from a financial professional or other professional who
            may have more information about your specific financial needs and circumstances.
          </p>
          <p>
            Nothing provided on the Site or through the Service should be construed or interpreted
            as professional advice or recommendations (i.e., we are not providing any financial,
            tax, or legal recommendations or advice or other professional advice). We are not liable
            to you for any advice provided to you by Providers or other third parties you may link
            to from our Site. We encourage you to consult a financial service provider, tax or legal
            professional to help you make any decisions associated with the use of the Service or
            the products and services provided by Providers.
          </p>
        </PrimaryText>
        <OwnUpHeader>Warranty Disclaimers</OwnUpHeader>
        <PrimaryText>
          <p>
            THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE
            FOREGOING, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT
            OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet
            your requirements or be available on an uninterrupted, secure or error-free basis. We
            make no warranty regarding the quality, accuracy, timeliness, truthfulness,
            completeness, or reliability of any Content. Some states do not allow the disclaimer of
            implied warranties, so the foregoing disclaimer may not apply to you.
          </p>
        </PrimaryText>
        <OwnUpHeader>Indemnity</OwnUpHeader>
        <PrimaryText>
          <p>
            You will indemnify and hold harmless Own Up and its officers, directors, employees, and
            agents, from and against any claims, disputes, demands, liabilities, damages, losses and
            costs and expenses, including, without limitation, reasonable legal and accounting fees
            arising out of or in any way connected with (i) your access to or use of the Services,
            (ii) your User Content, or (iii) your violation of these Terms.
          </p>
        </PrimaryText>
        <OwnUpHeader>Limitation of Liability</OwnUpHeader>
        <PrimaryText>
          <p>
            NEITHER OWN UP NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE
            SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
            DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION,
            COMPUTER DAMAGE, OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES ARISING OUT OF OR
            IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES,
            WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR
            ANY OTHER LEGAL THEORY, AND WHETHER OR NOT OWN UP HAS BEEN INFORMED OF THE POSSIBILITY
            OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
            ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY
            TO YOU.
          </p>
          <p>
            IN NO EVENT WILL OWN UP&apos;S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH
            THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU
            HAVE PAID TO OWN UP FOR USE OF THE SERVICES OR CONTENT OR ONE HUNDRED DOLLARS ($100), IF
            YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO OWN UP, AS APPLICABLE.
          </p>
          <p>
            THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
            THE BASIS OF THE BARGAIN BETWEEN OWN UP AND YOU.
          </p>
        </PrimaryText>
        <OwnUpHeader>Dispute Resolution</OwnUpHeader>
        <Subheader variant="box">GOVERNING LAW</Subheader>
        <PrimaryText>
          <p>
            These Terms and any action related thereto will be governed by the laws of the State of
            Delaware without regard to its conflict of laws provisions.
          </p>
        </PrimaryText>
        <Subheader variant="box">AGREEMENT TO ARBITRATE</Subheader>
        <PrimaryText>
          <p>
            You and Own Up agree that any dispute, claim, or controversy arising out of or relating
            to these Terms or the breach, termination, enforcement, interpretation, or validity
            thereof or the use of the Services or Content (collectively, “Disputes”) will be settled
            by binding arbitration, except that each party retains the right: (i) to bring an
            individual action in small claims court and (ii) to seek injunctive or other equitable
            relief in a court of competent jurisdiction to prevent the actual or threatened
            infringement, misappropriation, or violation of a party&apos;s copyrights, trademarks,
            trade secrets, patents, or other intellectual property rights (the action described in
            the foregoing clause (ii), an “IP Protection Action”). Without limiting the preceding
            sentence, you will also have the right to litigate any other Dispute if you provide Own
            Up with written notice of your desire to do so by email or regular mail at 101 Arch St,
            Suite 410, Boston, MA 02110 within thirty (30) days following the date you first accept
            these Terms (such notice, an “Arbitration Opt-out Notice”). If you don&apos;t provide
            Own Up with an Arbitration Opt-out Notice within the thirty (30) day period, you will be
            deemed to have knowingly and intentionally waived your right to litigate any Dispute
            except as expressly set forth in clauses (i) and (ii) above. The exclusive jurisdiction
            and venue of any IP Protection Action or, if you timely provide Own Up with an
            Arbitration Opt-out Notice, any Dispute will be the state and federal courts located in
            the Northern District of California and each of the parties hereto waives any objection
            to jurisdiction and venue in such courts. Unless you timely provide Own Up with an
            Arbitration Opt-out Notice, you acknowledge and agree that you and Own Up are each
            waiving the right to a trial by jury or to participate as a plaintiff or class member in
            any purported class action or representative proceeding. Further, unless both you and
            Own Up otherwise agree in writing, the arbitrator may not consolidate more than one
            person&apos;s claims, and may not otherwise preside over any form of any class or
            representative proceeding. If this specific paragraph is held unenforceable, then the
            entirety of this “Dispute Resolution” section will be deemed void. Except as provided in
            the preceding sentence, this “Dispute Resolution” section will survive any termination
            of these Terms.
          </p>
        </PrimaryText>
        <Subheader variant="box">ARBITRATION RULES</Subheader>
        <PrimaryText>
          <p>
            The arbitration will be administered by the American Arbitration Association (“AAA”) in
            accordance with the Commercial Arbitration Rules and the Supplementary Procedures for
            Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this
            “Dispute Resolution” section. (The AAA Rules are available at{' '}
            <UnderlinedLink
              href="https://www.adr.org/active-rules"
              aria-label="AAA Rules Site"
              rel="noreferrer"
              target="_blank"
            >
              https://www.adr.org/active-rules
            </UnderlinedLink>{' '}
            or by calling the AAA at{' '}
            <UnderlinedLink href="tel:1-800-778-7879">1-800-778-7879</UnderlinedLink>.) The Federal
            Arbitration Act will govern the interpretation and enforcement of this Section.
          </p>
        </PrimaryText>
        <Subheader variant="box">ARBITRATION PROCESS</Subheader>
        <PrimaryText>
          <p>
            A party who desires to initiate arbitration must provide the other party with a written
            Demand for Arbitration as specified in the AAA Rules. The AAA provides a general Demand
            for Arbitration and a separate Demand for Arbitration for California residents. The
            arbitrator will be either a retired judge or an attorney licensed to practice law and
            will be selected by the parties from the AAA&apos;s roster of arbitrators. If the
            parties are unable to agree upon an arbitrator within seven (7) days of delivery of the
            Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the
            AAA Rules.
          </p>
        </PrimaryText>
        <Subheader variant="box">ARBITRATION LOCATION AND PROCEDURE</Subheader>
        <PrimaryText>
          <p>
            Unless you and Own Up otherwise agree, the arbitration will be conducted in the county
            where you reside. If your claim does not exceed $10,000, then the arbitration will be
            conducted solely on the basis of the documents that you and Own Up submit to the
            arbitrator, unless you request a hearing or the arbitrator determines that a hearing is
            necessary. If your claim exceeds $10,000, your right to a hearing will be determined by
            the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to
            direct a reasonable exchange of information by the parties, consistent with the nature
            of the arbitration.
          </p>
        </PrimaryText>
        <Subheader variant="box">ARBITRATOR&apos;S DECISION</Subheader>
        <PrimaryText>
          <p>
            The arbitrator will render an award within the time frame specified in the AAA Rules.
            The arbitrator&apos;s decision will include the essential findings and conclusions upon
            which the arbitrator based the award. Judgment on the arbitration award may be entered
            in any court having jurisdiction thereof. The arbitrator&apos;s award of damages must be
            consistent with the terms of the “Limitation of Liability” section above as to the types
            and amounts of damages for which a party may be held liable. The arbitrator may award
            declaratory or injunctive relief only in favor of the claimant and only to the extent
            necessary to provide relief warranted by the claimant&apos;s individual claim. If you
            prevail in arbitration you will be entitled to an award of attorneys&apos; fees and
            expenses, to the extent provided under applicable law. Own Up will not seek, and hereby
            waives all rights it may have under applicable law to recover attorneys&apos; fees and
            expenses if it prevails in arbitration.
          </p>
        </PrimaryText>
        <Subheader variant="box">FEES</Subheader>
        <PrimaryText>
          <p>
            Your responsibility to pay any AAA filing, administrative, and arbitrator fees will be
            solely as set forth in the AAA Rules. However, if your claim for damages does not exceed
            $25,000, Own Up will pay all such fees unless the arbitrator finds that either the
            substance of your claim or the relief sought in your Demand for Arbitration was
            frivolous or was brought for an improper purpose (as measured by the standards set forth
            in Federal Rule of Civil Procedure 11(b)).
          </p>
        </PrimaryText>
        <OwnUpHeader>Changes</OwnUpHeader>
        <PrimaryText>
          <p>
            Notwithstanding the provisions of the “Changes to Terms or Services” section above, if
            Own Up changes this “Dispute Resolution” section after the date you first accepted these
            Terms (or accepted any subsequent changes to these Terms), you may reject any such
            change by sending us written notice (including by email to{' '}
            <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink>{' '}
            within 30 days of the date such change became effective, as indicated in the “Last
            Updated” date above or in the date of Own Up&apos;s email to you notifying you of such
            change. By rejecting any change, you are agreeing that you will arbitrate any Dispute
            between you and Own Up in accordance with the provisions of this “Dispute Resolution”
            section as of the date you first accepted these Terms (or accepted any subsequent
            changes to these Terms).
          </p>
        </PrimaryText>
        <OwnUpHeader>General Terms</OwnUpHeader>
        <PrimaryText>
          <p>
            These Terms constitute the entire and exclusive understanding and agreement between Own
            Up and you regarding the Services and Content, and these Terms supersede and replace any
            and all prior oral or written understandings or agreements between Own Up and you
            regarding the Services and Content. If any provision of these Terms is held invalid or
            unenforceable (either by an arbitrator appointed pursuant to the terms of the “Dispute
            Resolution” section above or by a court of competent jurisdiction, if you opt out of
            arbitration by sending us an Arbitration Opt-out Notice in accordance with the terms set
            forth above), that provision will be enforced to the maximum extent permissible and the
            other provisions of these Terms will remain in full force and effect.
          </p>
          <p>
            You may not assign or transfer these Terms, by operation of law or otherwise, without
            Own Up&apos;s prior written consent. Any attempt by you to assign or transfer these
            Terms, without such consent, will be null. Own Up may freely assign or transfer these
            Terms without restriction. Subject to the foregoing, these Terms will bind and inure to
            the benefit of the parties, their successors and permitted assigns.
          </p>
          <p>
            Any notices or other communications provided by Own Up under these Terms, including
            those regarding modifications to these Terms, will be given: (i) via email; or (ii) by
            posting to the Services. For notices made by e-mail, the date of receipt will be deemed
            the date on which such notice is transmitted.
          </p>
          <p>
            Own Up&apos;s failure to enforce any right or provision of these Terms will not be
            considered a waiver of such right or provision. The waiver of any such right or
            provision will be effective only if in writing and signed by a duly authorized
            representative of Own Up. Except as expressly set forth in these Terms, the exercise by
            either party of any of its remedies under these Terms will be without prejudice to its
            other remedies under these Terms or otherwise.
          </p>
        </PrimaryText>
        <OwnUpHeader>Contact Information</OwnUpHeader>
        <PrimaryText>
          <p>
            If you have any questions about these Terms or the Services, please contact Own Up at:
          </p>
          <p>
            Phone: <UnderlinedLink href="tel:844-947-2848">844-947-2848</UnderlinedLink> <br />
            Email:{' '}
            <UnderlinedLink href="mailto:support@ownup.com">support@ownup.com</UnderlinedLink>{' '}
            <br />
            Own Up <br />
            {street}, {unit}
            <br />
            {`${city}, ${state} ${zip}`}
          </p>
        </PrimaryText>
      </ContentWrapper>
    </PageWrapper>
  </Layout>
);

export default TermsAndConditions;
